.errors {
    p.text-danger {
        margin: 0px;
    }
}

.text-danger {
    color: #fb7676 !important;
}

.error-inputbox {
    height: 80px;
}

.error-inputbox1 {
    height: 60px;
}

.error-textarea {
    height: 120px;
}

select {
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;

    option {
        background-color: #fff;
        color: #686868;
    }
}

.nav-tabs .nav-item li {
    a.active {
        display: block;
        background-color: $primary-color !important;
        color: var(--white);
    }
}

.card-body {
    color: #000;
}

input,
textarea {
    background-color: #f3f3ff;
    transition: all 0.3s ease;
    border: none;

    &::-webkit-input-placeholder {
        color: $light-text;
    }

    &:hover {
        border: 1px solid lighten($semi-dark, 35%);
        box-shadow: none !important;
        transition: all 0.3s ease;
    }

    &:focus {
        box-shadow: none !important;
        border: 1px solid $primary-color !important;
    }
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {
    background-color: #f3f3ff;
    transition: all 0.3s ease;
    border: none;

    &::-webkit-input-placeholder {
        color: $light-text;
    }

    &:hover {
        border: none;
        box-shadow: none !important;
        transition: all 0.3s ease;
    }

    &:focus {
        box-shadow: none !important;
        border: none !important;
    }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: $primary-color !important;
    // background-color: lighten($primary-color, 30%);
    background-color: #fff !important;
    font-weight: bold !important;
}

.ng-option:hover {
    background-color: lighten($primary-color, 30%) !important;
}

.ng-select .ng-select-container{
    border: $input-border-width solid $input-border-color !important;
    &:hover{
        border: 1px solid #b9c1cc !important;
    }
}

.form-control.ng-pristine.ng-invalid.ng-touched {
    border: 1px solid #fb7676;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: lighten($body-color, 30%) !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened)> .ng-select-container{
    box-shadow: none !important;
    border: 1px solid $primary-color !important;
}

// .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
// .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
//     font-weight: lighter;
// }

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #423b3b !important;
}

.mdc-line-ripple::before {
    border: none !important;
}

.mdc-line-ripple::after {
    border: none !important;
}

.mat-mdc-form-field-bottom-align::before {
    height: 0px !important;
    display: none !important;
}

.mat-mdc-icon-button {
    padding-top: 0px !important;
}

.mdc-floating-label--float-above {
    transform: none !important;
    display: none !important;
}

.mat-mdc-text-field-wrapper {
    height: 28px !important;
}

.mat-datepicker-content {
    box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f !important;
    background-color: #fff !important;
    color: #000000de !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input{
    position: absolute !important;
    top: 17px !important;
  }
  .mat-mdc-icon-button.mat-mdc-button-base{
    position: relative !important;
    top: -7px !important;
  }
  .mat-mdc-icon-button{
    --mat-mdc-button-ripple-color: rgba(0,0,0,0) !important;
  }

  .mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before{
    background-color: #fff !important;
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix{
    position: relative !important;
    top: -15px !important;
  }

  .mat-mdc-form-field-focus-overlay{
    background-color: #fff !important;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled){
    background-color: #fff !important;
  }

  .mat-calendar-body-selected{
    background-color: $primary-color !important;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical){
    background-color: lighten($primary-color, 27%) !important;

  }

.focus:focus{
    border: none !important;
  }
  .focus:hover{
  border: none !important;
  }